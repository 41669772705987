export default [
    {
        path: '/settings/postal-codes',
        name: 'pagePostalCodesList',
        meta: { title: 'postalCodes.listTitle', roles: ['SETTING_POSTAL_CODE_VIEW'] },
        component: () => import('@/pages/settings/postal-codes'),
    },
    {
        path: '/settings/postal-codes/create',
        name: 'pagePostalCodesCreate',
        meta: { title: 'postalCodes.createFormTitle', roles: ['SETTING_POSTAL_CODE_VIEW'] },
        component: () => import('@/pages/settings/postal-codes/create'),
    },
    {
        path: '/settings/postal-codes/:id/edit',
        name: 'pagePostalCodesEdit',
        meta: { title: 'postalCodes.editFormTitle', roles: ['SETTING_POSTAL_CODE_VIEW'] },
        component: () => import('@/pages/settings/postal-codes/id/edit'),
    },
    {
        path: '/settings/postal-codes/import',
        name: 'pagePostalCodesImport',
        meta: { title: 'postalCodes.importFormTitle', roles: ['SETTING_POSTAL_CODE_VIEW'] },
        component: () => import('@/pages/settings/postal-codes/import.vue'),
    },
]
