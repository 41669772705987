import ccStatisticsDashboardRepository from "@/repository/admin/ccStatisticsDashboardRepository";
import StorageService from "@/services/storage.service";
import storageKeys from "@/constans/storageKeys";
import {DATE_FORM_FORMAT} from "@/constans/date";
import moment from "moment";
import TableOptions from "@/models/TableOptions";
import {mockPagination} from "@/helpers/filters";
import {defaultFilters} from "@/models/CCStatisticsDashboard/helpers";

const defaultWeek = moment().startOf('isoWeek').add(7, 'days').format(DATE_FORM_FORMAT)
const defaultDay = moment().add(1, 'days').format(DATE_FORM_FORMAT)
const state = {
  isTableView: StorageService.get(storageKeys.CC_DASHBOARD_IS_TABLE_VIEW) || false,
  filters: {
    statisticsByDay: StorageService.get(storageKeys.CC_DASHBOARD_DAY_CHART) || { date: defaultDay },
    statisticsByWeek: StorageService.get(storageKeys.CC_DASHBOARD_WEEK_CHART) || { date: defaultWeek },
    statisticsByMonth: StorageService.get(storageKeys.CC_DASHBOARD_MONTH_CHART) || { date: defaultDay },
    tableStatistics: StorageService.get(storageKeys.CC_DASHBOARD_TABLE) || { ...defaultFilters },
  },
  dashboardList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  isTableView: state => state.isTableView,
  tableOptions: state => state.tableOptions,
  dashboardList: state => state.dashboardList,
}

const actions = {
  async loadStatisticsByDay(context, {filters, innerFilters}) {
    context.commit('setStatisticsByDayFilters', innerFilters)
    StorageService.set(storageKeys.CC_DASHBOARD_DAY_CHART, innerFilters)

    const { data } = await ccStatisticsDashboardRepository.statisticsByDay(filters)
    return data
  },
  async loadStatisticsByWeek(context, {filters, innerFilters}) {
    context.commit('setStatisticsByWeekFilters', innerFilters)
    StorageService.set(storageKeys.CC_DASHBOARD_WEEK_CHART, innerFilters)

    const { data } = await ccStatisticsDashboardRepository.statisticsByWeek(filters)
    return data
  },
  async loadStatisticsByMonth(context, {filters, innerFilters}) {
    context.commit('setStatisticsByMonthFilters', innerFilters)
    StorageService.set(storageKeys.CC_DASHBOARD_MONTH_CHART, innerFilters)

    const { data } = await ccStatisticsDashboardRepository.statisticsByMonth(filters)
    return data
  },
  async loadTableStatisticsByDay(context, filters) {
    context.commit('setTableStatisticsFilters', filters)
    StorageService.set(storageKeys.CC_DASHBOARD_TABLE, filters)

    const { data } = await ccStatisticsDashboardRepository.tableStatisticsByDay(filters.filter)
    context.commit('setTableData', {
      data,
      pagination: mockPagination(Object.values(data.rows), filters),
      filters,
    })
    return { dashboardList: data }
  },
  async loadTableStatisticsByWeek(context, filters) {
    context.commit('setTableStatisticsFilters', filters)
    StorageService.set(storageKeys.CC_DASHBOARD_TABLE, filters)

    const { data } = await ccStatisticsDashboardRepository.tableStatisticsByWeek(filters.filter)
    context.commit('setTableData', {
      data,
      pagination: mockPagination(Object.values(data.rows), filters),
      filters,
    })
    return { dashboardList: data }
  },
  async loadTableStatisticsByMonth(context, filters) {
    context.commit('setTableStatisticsFilters', filters)
    StorageService.set(storageKeys.CC_DASHBOARD_TABLE, filters)

    const { data } = await ccStatisticsDashboardRepository.tableStatisticsByMonth(filters.filter)
    context.commit('setTableData', {
      data,
      pagination: mockPagination(Object.values(data.rows), filters),
      filters,
    })
    return { dashboardList: data }
  },
  updateIsTableViewDashboard(context, date){
    context.commit('setIsTableViewDashboard', date)
    StorageService.set(storageKeys.CC_DASHBOARD_IS_TABLE_VIEW, date)
  },
  updateSavedFilters(context, filters) {
    context.commit('setTableStatisticsFilters', filters)
    StorageService.set(storageKeys.CC_DASHBOARD_TABLE, filters)
    context.commit('setTableData', {
      data: context.getters.dashboardList,
      pagination: mockPagination(Object.values(context.getters.dashboardList.rows), filters),
      filters,
    })
  },
}
const mutations = {
  setStatisticsByDayFilters(state, filters) {
    state.filters.statisticsByDay = { ...filters }
  },
  setStatisticsByWeekFilters(state, filters) {
    state.filters.statisticsByWeek = { ...filters }
  },
  setStatisticsByMonthFilters(state, filters) {
    state.filters.statisticsByMonth = { ...filters }
  },
  setTableStatisticsFilters(state, filters) {
    state.filters.tableStatistics = { ...filters }
  },
  setTableData(state, {data, pagination, filters}) {
    state.dashboardList = data
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setIsTableViewDashboard(state, data) {
    state.isTableView = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
