import rejectReasonRepo from '@/repository/admin/rejectReasonRepository'
import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/StatusReason/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import i18n from '@/plugins/vue-i18n'

const state = {
  filters: StorageService.get(storageKeys.STATUS_REASON_FILTERS) || { ...defaultFilters },
  statusReasonList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  statusReasonList: state => state.statusReasonList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadStatusReasonList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.STATUS_REASON_FILTERS, filters)
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await rejectReasonRepo.list(preparedFilters)
    // data.items.forEach(i => i.name = i18n.t(`rejectReason.reason${i.id}`))
    context.commit('setStatusReasonList', { data, filters })

    return { statusReasonList: data.items, pagination: data.pagination }
  },
  async searchStatusReasonList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await rejectReasonRepo.list(preparedFilters)
    data.items.forEach(i => i.name = i18n.t(`rejectReason.reason_${i.name.toLowerCase().replace(/\s+/g, '_')}`))
    return { statusReasonList: data.items, pagination: data.pagination }
  },
  async loadStatusReason(context, id) {
    const { data } = await rejectReasonRepo.get(id)
    return data
  },
  createStatusReason(context, payload) {
    return rejectReasonRepo.post(payload)
  },
  updateStatusReason(context, payload) {
    const { id, ...data } = payload
    return rejectReasonRepo.put(id, data)
  },
  deleteStatusReason(context, payload) {
    return rejectReasonRepo.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.STATUS_REASON_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.STATUS_REASON_FILTERS, updatedFilters)
  },
}

const mutations = {
  setStatusReasonList(state, { data, filters }) {
    state.statusReasonList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
