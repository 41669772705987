import OfferSetRepository from '@/repository/admin/offerSetRepository'
import OfferSetProductRepository from '@/repository/admin/offerSetProductRepository'

const actions = {
  async loadOfferSet(context, payload) {
    const { data } = await OfferSetRepository.list(payload)
    return data
  },
  createOfferSet(context, payload) {
    return OfferSetRepository.post(payload)
  },
  updateOfferSet(context, payload) {
    const { id, ...data } = payload
    return OfferSetRepository.put(id, data)
  },
  deleteOfferSet(context, payload) {
    return OfferSetRepository.delete(payload)
  },
  createOfferSetProduct(context, payload) {
    return OfferSetProductRepository.post(payload)
  },
  updateOfferSetProduct(context, payload) {
    const { id, ...data } = payload
    return OfferSetProductRepository.put(id, data)
  },
  deleteOfferSetProduct(context, payload) {
    return OfferSetProductRepository.delete(payload)
  },
}

export default {
  namespaced: true,
  actions,
}
