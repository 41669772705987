export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    countries: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    groups: {
      id: {
        value: '',
        operator: 'in',
      },
    },
  },
  custom_filter: {
    status: null
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
