export default {
  ANSWER_FILTERS: 'filters_answer',
  APPROVE_SPEED: 'filters_approve_speed',
  BLACK_PHONE_FILTERS: 'filters_black_phone',
  BUYBACK_DYNAMICS_FILTERS: 'filters_buyback_dynamics',
  CALL_CONTROLLER: 'filters_call_controller',
  CAMPAIGN_FILTERS: 'filters_campaign',
  CAMPAIGN_FILTERS_QUEUE: 'filters_campaign_queue',
  CALL_STATISTICS_FILTERS: 'filters_call_statistics',
  CALL_CENTER_PRICE_FILTERS: 'filters_call_center_price',
  CALL_CENTRE_FILTERS: 'filters_call_centre',
  CITY_FILTERS: 'filters_city',
  CC_DASHBOARD_DAY_CHART: 'filters_cc_dashboard_day_chart',
  CC_DASHBOARD_WEEK_CHART: 'filters_cc_dashboard_week_chart',
  CC_DASHBOARD_MONTH_CHART: 'filters_cc_dashboard_month_chart',
  CC_DASHBOARD_TABLE: 'filters_cc_dashboard_table',
  CC_DASHBOARD_IS_TABLE_VIEW: 'filters_cc_dashboard_is_table_view',
  COUNTRY_FILTERS: 'filters_country',
  DELIVERY_WAREHOUSE_STOCK_FILTERS: 'filters_delivery_warehouse_stock',
  DELIVERY_PRODUCT_STOCK_FILTERS: 'filters_delivery_product_stock',
  COUNTRY_SMS_SETTING_FILTERS: 'filters_country_sms_setting',
  DELIVERY_TAX_FILTERS: 'filters_delivery_tax',
  DASHBOARD_HANGING_RANSOM: 'dashboard_hanging_ransom_filters',
  DASHBOARD_DELIVERY_SERVICE: 'dashboard_delivery_service_filters',
  DASHBOARD_APPROVE_PENSIONER: 'dashboard_approve_pensioner_filters',
  DASHBOARD_APPROVES_BY_TIME: 'dashboard_approves_by_time_filters',
  DASHBOARD_AMOUNT_AND_AVERAGE: 'dashboard_amount_and_average_filters',
  DASHBOARD_OPERATOR_DAILY_EARNING: 'dashboard_operator_daily_earning',
  DASHBOARD_NO_RANSOM_72_HOURS: 'dashboard_no_ransom_72_hours',
  DELIVERY_COURIER_FILTERS: 'filters_delivery_courier',
  DELIVERY_EXCLUDED_RULES_FILTERS: 'filters_delivery_excluded_rules',
  DELIVERY_INTERVAL_FILTERS: 'filters_delivery_interval',
  DELIVERY_POINT_FILTERS: 'filters_delivery_point',
  DELIVERY_POINTS_ORDER_FILTERS: 'filters_delivery_points_order',
  DELIVERY_POST_FILTERS: 'filters_delivery_post',
  DELIVERY_SERVICE_CITY_FILTERS: 'filters_service_cities',
  DELIVERY_SERVICES_FILTERS: 'filters_delivery_service',
  DELIVERY_SERVICE_ANALYTIC: 'filters_delivery_service_analytic',
  DELIVERY_WAREHOUSE_FILTERS: 'filters_warehouses',
  DELIVERY_WEEKEND_FILTERS: 'filters_delivery_weekends',
  DISTRIBUTION_CHECK_FILTERS: 'filters_distribution',
  DISTRICT_FILTERS: 'filters_district_settings',
  DOSTAVIM_FILTERS: 'filters_dostavim',
  FINANCE_SETTINGS_FILTERS: 'filters_finance_settings',
  FINANCIAL_REPORT_FILTERS: 'filters_financial_report',
  FINANCIAL_REPORT_ORDERS_FILTERS: 'filters_financial_report_orders',
  GRADE_HISTORY_FILTERS: 'filters_grade_history',
  GROUP_TYPES_FILTERS: 'filters_group_types',
  KLN_ASSESSMENT_FILTERS: 'filters_kln_assessment',
  KLN_OPERATOR_ASSESSMENT_FILTERS: 'filters_kln_operator_assessment',
  LANDING_FILTERS: 'filters_landing',
  LANGUAGE_FILTERS: 'filters_language_country',
  LEGAL_PERSONS_STATS_FILTERS: 'filters_legal_persons_stats',
  LOG_ABNORMAL: 'filters_log_abnormal',
  LOG_FILTERS_AUTH: 'filters_log_auth',
  LOG_FILTERS_ORDER: 'filters_log_order',
  LOG_WATCH_NUMBERS: 'filters_log_watch_number',
  LOG_ACCOUNTS: 'filters_log_accounts',
  LOGISTIC_PRICE_FILTERS: 'filters_logistic_price',
  METASHIP_ACCOUNTS_FILTERS: 'filters_metaship_accounts',
  METASHIP_ACCOUNT_FILTERS: 'filters_metaship_account',
  MESSAGE_CHAIN_UNIT_FILTERS: 'filters_message_chain_unit',
  OFFER_COLD_STATISTIC_FILTERS: 'filters_offer_cold_statistic',
  OFFER_FILTERS: 'filters_offers',
  OFFER_GROUPS_FILTERS: 'filters_offer_groups',
  OFFER_NICHES_FILTERS: 'filters_offer_niches',
  OFFER_RATING_FILTERS: 'filters_rating_offers',
  OFFER_STATISTICS_FILTERS: 'filters_offer_statistics',
  OFFER_TICKETS_FILTERS: 'filters_offer_tickets',
  OFFER_MY_TICKETS_FILTERS: 'filters_offer_my_tickets',
  OFFERS_NORMS_FILTERS: 'filter_offer_norms',
  OPERATOR_GRADES_FILTERS: 'filters_operator_grades',
  ORDER_FILTERS: 'filters_orders',
  ORDER_AVERAGE_CHECK: 'filters_average_check',
  ORDER_OPERATOR_FILTERS: 'filters_orders_operator',
  ORDER_FRAUD_FILTERS: 'filters_order_fraud',
  ORDER_HANGING_RANSOM_FILTERS: 'filters_hanging_ransom',
  ORDER_LOGISTICS_FILTERS: 'filters_order_logistics',
  ORDER_NOTICE_DEPARTMENT_FILTERS: 'filters_order_notice_department',
  ORDER_SELECTED_COLUMNS: 'columns_order',
  ORDER_TAG_FILTERS: 'filters_order_tag',
  ORDER_TRASH_CHECKED_FILTERS: 'filters_order_trash_checked',
  ORDER_TRASH_DISPUTE_FILTERS: 'filters_order_trash_dispute',
  ORDER_TRASH_FILTERS: 'filters_order_trash',
  ORDER_SUSPICIOUS_FILTERS: 'filters_suspicious_orders',
  ORDER_AUTOCALL_FILTERS: 'filters_autocall_orders',
  ORGANIZATION_FILTERS: 'filters_organization',
  PAYMENT_HOLD_FILTERS: 'filters_payment_hold',
  PAYMENT_PROFIT_FILTERS: 'filters_payment_profit',
  PENALTY_FILTERS: 'filters_penalty',
  PHONE_CODES_FILTERS: 'filters_phone_codes',
  PREPAYMENT_FILTERS: 'filters_prepayment',
  PREPAYMENT_FILTERS_TWO: 'filters_prepayment_two',
  PREPAYMENT_FILTERS_TEAMLEAD: 'filters_prepayment_teamlead',
  PREPAYMENT_FILTERS_TEAMLEAD_TWO: 'filters_prepayment_teamlead_two',
  PRODUCT_GIFTS_FILTERS: 'filters_product_gifts',
  PRODUCT_SETS_FILTERS: 'filters_product_sets',
  PRODUCT_TYPES_FILTERS: 'filters_product_type',
  IP_ADDRESS_FILTERS: 'filters_ip_address',
  PRODUCTS_FILTERS: 'filters_products',
  QUESTIONNAIRES_FILTERS: 'filters_questionnaires',
  QUESTIONNAIRES_KNL_FILTERS: 'filters_questionnaires_knl',
  QUESTIONNAIRES_ORDER_FILTERS: 'filters_questionnaires_order',
  RECONFIRMATION_STATISTIC_FILTERS: 'filters_reconfirmation_statistic',
  REJECTION_STATISTICS_FILTERS: 'filters_rejection_statistics',
  REGION_FILTERS: 'filters_region_settings',
  REPEATED_CONFIRM_FILTERS: 'filters_repeated_confirm',
  STATISTICS_KLN_FILTERS: 'filters_statistics_kln',
  SALARY_BONUS_FILTERS: 'filters_salary_bonus',
  SALES_MODEL_FILTERS: 'filters_sales_model',
  SALES_SCRIPT_FILTERS: 'filters_sales_script',
  SALES_SCRIPT_TEMPLATE_FILTERS: 'filters_sales_script_template',
  SMS_CAMPAIGN_FILTERS: 'sms_filters_campaign',
  SMS_TEMPLATE_FILTERS: 'sms_template_filters',
  SMS_OPERATOR_RELATION_FILTERS: 'sms_operator_relation_filters',
  SMS_CHAIN_FILTERS: 'sms_chain_filters',
  STATUS_REASON_FILTERS: 'filters_status_reason',
  TICKET_TYPES_FILTERS: 'filters_ticket_types',
  UNPAID_ORDERS_FILTERS: 'filters_unpaid_orders',
  USER_DAY_SCHEDULE_FILTERS: 'filters_user_day_schedule',
  USER_DAY_OPERATOR_SCHEDULE_FILTERS: 'filters_user_day_operator_schedule',
  USER_DAY_OPERATOR_LIST_FILTERS: 'filters_user_day_list',
  USER_FILTERS: 'filters_users',
  USER_SCHEDULE_FILTERS: 'filters_users_schedule',
  USER_GROUP_FILTERS: 'filters_user_groups',
  USER_GROUP_TYPE_FILTERS: 'filters_user_group_types',
  USER_PENALTY_FILTERS: 'filters_user_penalty',
  USER_ROLES_FILTERS: 'filters_user_roles',
  USER_SALARY_FILTERS: 'filters_user_salary',
  WEB_ANALYTICA_FILTERS: 'filters_web_analytica',
  WORK_TIME_FILTERS: 'filters_work_time',
  ORDER_DELIVERY_STATUS_LOG: 'filters_order_delivery_status_log',
  // __auto_generated_storage_keys__
  CALL_CENTER_WEBS_FILTERS: 'filters_call_center_webs',
  OPERATOR_STATUSES_FILTERS: 'filters_operator_statuses',
  ORDER_SUB_STATUSES_FILTERS: 'filters_order_sub_statuses',
  WRITE_OFF_HISTORY_FILTERS: 'filters_write_off_history',
  POSTAL_CODES_FILTERS: 'filters_postal_codes',
}
