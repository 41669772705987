import moment from "moment/moment";
import {DATE_FORM_FORMAT} from "@/constans/date";

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
  dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
  filter: {
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      niche: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      salesType: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      mainProductType: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      salesModel: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    deliveryService: {
      id: {
        value: '',
        operator: 'in',
      },
      type: [],
    },
    city: {
      id: {
        value: '',
        operator: 'in',
      },
      district: {
        region: {
          id: {
            value: '',
            operator: 'in',
          },
        },
      },
    },
    country: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    operator: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    tags: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    organization: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    warehouse: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    gender: null,
    age: null,
    withErrors: null,
  },
  search: '',
  clientOptions: {
    filterPanelExpanded: false,
  },
}
