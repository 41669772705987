import moment from 'moment'
import { DATE_FORM_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
    groupByWeb: false,
    offers: [],
    groups: [],
    web: [],
  },
  search: '',
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export default function customOfferLabel(option) {
  return option.name
}
