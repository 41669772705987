import moment from 'moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    responsibleGroups: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    order: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    responsibleUsers: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    ticketType: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    priority: null,
    status: null,
    active: 1,
    deadLine: {
      value: `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${
        moment().endOf('month').format(DATE_TIME_FORM_FULL_FORMAT,)}`,
      operator: 'bwn',
    },
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
