import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'
import moment from 'moment'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  dateFrom: moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT),
  dateTo: moment().endOf('month').format(DATE_TIME_FORM_FULL_FORMAT),
  sortBy: ['name'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
