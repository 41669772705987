import moment from "moment/moment";
import {DATE_FORM_FORMAT} from "@/constans/date";

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    date: moment().add(1, 'days').format(DATE_FORM_FORMAT),
    groupBy: 'week'
  }
}