import blackPhone from './black-phone'
import city from './city'
import country from './country'
import currencies from './сurrencies'
import currencyConversion from './сurrency-conversion'
import delivery from './delivery-points'
import deliveryExcludedRules from './delivery-excluded-rules'
import deliveryInterval from './delivery-interval'
import deliveryService from '@/router/settings/delivery-service'
import deliveryWarehouse from './delivery-warehouse'
import deliveryWeekend from './delivery-weekend'
import district from './district'
import finance from './finance'
import groupTypes from './group-types'
import landing from './landing'
import language from './language'
import limitTime from './limit-time'
import offer from './offer'
import offerGroup from './offer-group'
import offerNiche from './offer-niche'
import orderTag from './order-tag'
import organization from '@/router/settings/organization'
import paymentHold from './payment-hold'
import paymentProfit from './payment-profit'
import phoneCodes from './phone-codes'
import product from './product'
import productGift from './product-gift'
import productSets from './product-sets'
import productType from './product-type'
import ipAddress from './ipAdress'
import questionnaires from './questionnaires'
import questionnaireKnl from '@/router/call-centre/questionnaire-knl'
import region from './region'
import salaryBonus from './salary-bonus'
import salesModel from './sales-model'
import salesScript from './sales-script'
import salesScriptTemplate from './sales-script-template'
import smsTemplate from '@/router/settings/sms-template'
import statusReason from './status-reason'
import user from './user'
import userGroup from './user-group'
import userPenalty from './user-penalty'
import userRole from './user-role'
import permission from '@/router/settings/permission'
import ticketTypes from '@/router/settings/ticket-types'
import userSchedule from '@/router/settings/user-schedule'
import smsSettings from '@/router/settings/sms-settings'
// __generator_imports_settings__
import callCenterWebsRoute from './call-center-webs'
import orderSubStatusesRoute from './order-sub-statuses'
import writeOffHistoryRoute from './write-off-history'
import postalCodesRoute from './postal-codes'

const routers = [
  ...blackPhone,
  ...city,
  ...country,
  ...currencies,
  ...currencyConversion,
  ...delivery,
  ...deliveryExcludedRules,
  ...deliveryInterval,
  ...deliveryService,
  ...deliveryWarehouse,
  ...deliveryWeekend,
  ...district,
  ...finance,
  ...groupTypes,
  ...landing,
  ...language,
  ...limitTime,
  ...offer,
  ...offerGroup,
  ...offerNiche,
  ...orderTag,
  ...organization,
  ...paymentHold,
  ...paymentProfit,
  ...permission,
  ...phoneCodes,
  ...product,
  ...productGift,
  ...productSets,
  ...productType,
  ...ipAddress,
  ...questionnaires,
  ...questionnaireKnl,
  ...region,
  ...salaryBonus,
  ...salesModel,
  ...salesScript,
  ...salesScriptTemplate,
  ...smsTemplate,
  ...statusReason,
  ...ticketTypes,
  ...user,
  ...userGroup,
  ...userPenalty,
  ...userRole,
  ...userSchedule,
  ...smsSettings,
  // __imported_entities_settings__
  ...callCenterWebsRoute,
  ...orderSubStatusesRoute,
  ...writeOffHistoryRoute,
  ...postalCodesRoute,
  {
    path: '/settings',
    name: 'settingsDashboard',
    meta: { title: 'page.settingsDashboard' },
    component: () => import('@/pages/settings/Dashboard'),
  },
  {
    path: '/settings/stop-words',
    name: 'pageStopWordsList',
    meta: { title: 'page.pageStopWordsList', roles: ['SETTING_STOP_WORD_VIEW'] },
    component: () => import('@/pages/settings/stop-words'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    meta: { title: 'page.pageNotFound' },
    component: () => import('@/pages/settings/not-found'),
  },
]
export default routers
