import moment from 'moment/moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFiltersAutocall = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    createdAt: {
      value: `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${
        moment().endOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}`,
      operator: 'bwn',
    },
    country: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
      niche: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    status: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    webId: {
      value: '',
      operator: 'in',
    },
    operator: {
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    telephonyRefId: {
      value: 1,
      operator: 'isnt_null',
    },
  },
  sortBy: ['telephonyCreatedAt'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
    dateType: 'createdAt',
  },
}
