export default [
    {
        path: '/settings/order-substatuses',
        name: 'pageOrderSubStatusesList',
        meta: { title: 'orderSubStatuses.listTitle', roles: ['SETTING_ORDER_SUB_STATUS_VIEW'] },
        component: () => import('@/pages/settings/order-sub-statuses'),
    },
    {
        path: '/settings/order-substatuses/create',
        name: 'pageOrderSubStatusesCreate',
        meta: { title: 'orderSubStatuses.createFormTitle', roles: ['SETTING_ORDER_SUB_STATUS_VIEW'] },
        component: () => import('@/pages/settings/order-sub-statuses/create'),
    },
    {
        path: '/settings/order-substatuses/:id/edit',
        name: 'pageOrderSubStatusesEdit',
        meta: { title: 'orderSubStatuses.editFormTitle', roles: ['SETTING_ORDER_SUB_STATUS_VIEW'] },
        component: () => import('@/pages/settings/order-sub-statuses/id/edit'),
    },
]
