import moment from 'moment/moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

const startOfMonth = moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)
const endOfMonth = moment().endOf('month').format(DATE_TIME_FORM_FULL_FORMAT)
export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    country: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    deliveryService: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    date: {
      value: `${startOfMonth}|${endOfMonth}`,
      operator: 'bwn',
    },
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
