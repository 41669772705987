import Vue from 'vue'

import {
  getDateByTimeZone,
  formatDate,
  convertSecondsToFormatDate,
  formatRelativeTime,
  formatDateToCurrentUserCountry,
} from '@/helpers/date'
import DateConverter from '@/models/DateConverter'
import { formatAsPercentage, formatNumber } from '@/helpers/number'
import {
  renderArrayOfObjectsByKey,
  renderValue,
  renderValueByKey,
  renderValueJoinBySymbol,
  renderValueYesNo,
} from '@/helpers/render-value'
import { capitalizeFirstLetter } from '@/helpers/string'
import { stringCroppedView } from '@/helpers/cropString'

Vue.filter('getDateTimeZone', str => getDateByTimeZone(str))
Vue.filter('formatDateToCurrentUserCountry', formatDateToCurrentUserCountry)
Vue.filter('formatDate', formatDate)
Vue.filter('capitalizeFirstLetter', capitalizeFirstLetter)
Vue.filter('convertToLocalDate', DateConverter.convertDateToUserDateFormatted)
Vue.filter('formatAsPercentage', formatAsPercentage)
Vue.filter('formatNumber', formatNumber)
Vue.filter('renderValue', renderValue)
Vue.filter('renderValueByKey', renderValueByKey)
Vue.filter('renderValueYesNo', renderValueYesNo)
Vue.filter('renderValueJoinBySymbol', renderValueJoinBySymbol)
Vue.filter('formatTime', convertSecondsToFormatDate)
Vue.filter('formatRelativeTime', formatRelativeTime)
Vue.filter('cropString', stringCroppedView)
Vue.filter('renderArrayOfObjectsByKey', renderArrayOfObjectsByKey)