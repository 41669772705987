import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'
import { DATE_FORM_FORMAT } from '@/constans/date'
import moment from 'moment'
import { OPERATOR_STATUS } from '@/constans/operatorStatuses'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
    status: OPERATOR_STATUS.NOT_AVAILABLE,
    timeType: 'avg',
    userGroups: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    country: null,
  },
  sortBy: ['id'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedWorkTimeFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)

  filters.userGroups.id.value = joinBySymbol(filter.userGroups)
  filters.country = filter.country
  filters.timeType = filter.timeType
  filters.dateFrom = filter.dateFrom
  filters.dateTo = filter.dateTo
  return filters
}
