import moment from 'moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  objectType: 'order',
  objectId: undefined,
  event: [],
  loggedAt: {
    gte: `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}`,
    lte: `${moment().endOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}`,
  },
  order_date: 'desc',
  source: undefined,
}
