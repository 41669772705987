import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/WriteOffHistory/helpers'
import writeOffHistoryRepository from '@/repository/admin/writeOffHistoryRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import i18n from '@/plugins/vue-i18n'
import { formatDate } from '@/helpers/date'
import { DATE_FORMAT } from '@/constans/date'
const { saveAs } = require('file-saver')

const state = {
  list: [],
  filters: StorageService.get(storageKeys.WRITE_OFF_HISTORY_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.WRITE_OFF_HISTORY_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await writeOffHistoryRepository.list(preparedFilters)

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async loadExportFile(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedDeepFilters = {}
    if(appliedFilters.date){
      const date = appliedFilters.date.value
      const [dateFrom, dateTo] = date.split('|')
      preparedDeepFilters.dateFrom = dateFrom
      preparedDeepFilters.dateTo = dateTo
      delete appliedFilters.date
    }
    preparedDeepFilters.warehouses = appliedFilters?.warehouseStock?.warehouse &&
      appliedFilters?.warehouseStock?.warehouse?.id?.value.split('|') || null
    preparedDeepFilters.products = appliedFilters?.warehouseStock?.product &&
      appliedFilters?.warehouseStock?.product?.id?.value.split('|') || null
    preparedDeepFilters.countries = appliedFilters?.order &&
      appliedFilters?.order?.country?.id?.value.split('|') || null
    const prepFilters = omitEmptyDeepFilters(preparedDeepFilters)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: prepFilters,
      cfFilter: appliedCfFilters,
    })

    const response = await writeOffHistoryRepository.export(
      { ...preparedFilters.filter },
      { responseType: 'blob' },
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('writeOffHistory.listTitle')} ${formatDate(
      prepFilters.dateFrom,
      DATE_FORMAT,
    )}-${formatDate(prepFilters.dateTo, DATE_FORMAT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async searchWriteOffHistory(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await writeOffHistoryRepository.list(preparedFilters)

    return { products: data.items, pagination: data.pagination }
  },
  async loadItem(context, id) {
    const { data } = await writeOffHistoryRepository.get(id)
    return data
  },
  createItem(context, payload) {
    return writeOffHistoryRepository.post(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return writeOffHistoryRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return writeOffHistoryRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.WRITE_OFF_HISTORY_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.WRITE_OFF_HISTORY_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
