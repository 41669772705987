import { RelatedStatusOfferRepository } from '@/repository/admin'

const actions = {
  async loadRelatedOffers(context, payload) {
    const { data } = await RelatedStatusOfferRepository.list(payload)
    return data
  },
  createRelatedOffer(context, payload) {
    return RelatedStatusOfferRepository.post(payload)
  },
  updateRelatedOffer(context, payload) {
    const { id, ...data } = payload
    return RelatedStatusOfferRepository.put(id, data)
  },
  deleteRelatedOffer(context, payload) {
    return RelatedStatusOfferRepository.delete(payload)
  },
}

export default {
  namespaced: true,
  actions,
}
