import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'
import moment from 'moment'
import { createCustomDateFilter } from '@/helpers/filters'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    ...createCustomDateFilter(
      'createdAt',
      `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${moment()
        .endOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}`,
    ),
    status: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    city: {
      id: {
        value: '',
        operator: 'in',
      },
      district: {
        id: {
          value: '',
          operator: 'in',
        },
        region: {
          id: {
            value: '',
            operator: 'in',
          },
          country: {
            id: {
              value: '',
              operator: 'in',
            },
          },
        },
      },
    },
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    deliveryService: {
      id: {
        value: '',
        operator: 'in',
      },
      type: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    products: {
      product: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    warehouse: {
      id: {
        value: '',
        operator: 'in',
      },
    },
  },
  custom_filter: {
    hours_from_approve: '',
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
    dateType: 'createdAt',
    selectedColumns: [],
  },
}
