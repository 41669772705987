import SuperRepository from '../superRepository'

export default new (class deliveryWarehouseRepository extends SuperRepository {
  baseUri() {
    return 'warehouse'
  }

  statistic = async ({ params, id }) =>
    this.httpClient().get(`${this.baseUri()}/${id}/delivery_service_stats`, params)

  syncWarehousesOneC = async () => this.httpClient().get(`${this.baseUri()}/synchronize`)
  import = async (data, params) => this.httpClient().postFile(`${this.baseUri()}_stock_import/import`, data, params);
  preview = async (data, params) => this.httpClient().postFile(`${this.baseUri()}_stock_import/preview`, data, params);
})()
