export default [
  {
    path: '/settings/sms-template',
    name: 'pageSmsTemplate',
    meta: { title: 'page.pageSmsTemplate', roles: ['SETTING_SMS_TEMPLATE_VIEW'] },
    component: () => import('@/pages/settings/sms-template'),
  },
  {
    path: '/settings/sms-template/create',
    name: 'pageSmsTemplateCreate',
    meta: { title: 'page.pageSmsTemplateCreate', roles: ['SETTING_SMS_TEMPLATE_VIEW'] },
    component: () => import('@/pages/settings/sms-template/create.vue'),
  },
  {
    path: '/settings/sms-template/:id/edit',
    name: 'pageSmsTemplateEdit',
    meta: { title: 'page.pageSmsTemplateEdit', roles: ['SETTING_SMS_TEMPLATE_VIEW'] },
    component: () => import('@/pages/settings/sms-template/id/edit.vue'),
  },
]
