export const defaultFilters = {
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
        country: null,
        region: null,
        deliveryService: null,
        warehouse: null,
        active: 1
    },
    sortBy: ['active', 'id'],
    sortDesc: [true, true],
    clientOptions: {
        filterPanelExpanded: false,
    },
}
