import { DATE_FORM_FORMAT } from '@/constans/date'
import moment from 'moment'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
    firstGroup: 'week',
    secondGroup: 'offer',
    filterDateBy: 'created_at',
    offers: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    offerGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    webs: [],
    countries: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    regions: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    products: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    tags: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    cities: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    niches: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    operators: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    landings: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    warehouses: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    salesModels: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    questionnaires: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    productTypes: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    userGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    salesTypes: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    deliveryServices: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    gender: null,
    ages: [],
  },
  search: '',
  clientOptions: {
    filterPanelExpanded: false,
  },
}
