<template>
  <v-chip v-bind="$attrs" v-on="$listeners" :color="backgroundColor" :text-color="textColor">
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </v-chip>
</template>

<script>
import { mapGetters } from 'vuex'
import { getTimezoneByCountryCode } from '@/helpers/date'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export default {
  name: 'UiChip',
  props: {
    status: {
      type: String,
      default: '',
    },
    callbackTime: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('ProfileModule', ['profile']),
    isCallbackExpired(){
      const userProfileTimeZone = getTimezoneByCountryCode()
      const currentUserTime = this.$moment.utc().tz(userProfileTimeZone).format(DATE_TIME_FORM_FULL_FORMAT)
      return this.$moment(this.callbackTime).isBefore(this.$moment(currentUserTime))
    },
    backgroundColor() {
      switch (this.status) {
        case 'approved':
        case 'awaiting_ransom':
        case 'shipped':
        case 'delivered':
        case 'paid':
        case 'partially_paid':
        case 'packaging':
          return 'success'
        case 'trash':
        case 'suspicious':
          return 'warning'
        case 'callback':
          return this.isCallbackExpired ? '#FF8C00' : 'warning'
        case 'no_ransom':
        case 'canceled':
        case 'incorrect':
          return 'error'
        default:
          return 'black lighten-5'
      }
    },
    textColor() {
      switch (this.status) {
        case 'processing':
        case 'call':
        case 'accepted':
          return 'black darken-3'
        default:
          return 'white'
      }
    },
  },
}
</script>

<style scoped></style>
