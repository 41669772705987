<template>
  <div class="d-flex">
    <v-text-field
      v-model="getValue"
      :error-messages="getErrorMsg"
      :error="error"
      outlined
      shaped
      :hide-details="hideDetails"
      v-bind="$attrs"
      v-on="$listeners"
      class="ui-text-field"
      :class="{'select-extended': selectExtended}"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>
    </v-text-field>
    <slot name="customSelect"></slot>
  </div>
</template>

<script>
import { getErrorMsg } from '@/helpers/validation'

export default {
  name: 'ui-text-field',
  props: {
    value: null,
    errorMessages: {
      type: [String, Array, Object],
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    selectExtended: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    error() {
      if (!this.value) return this.$attrs.error
      return this.value.$error
    },
    getErrorMsg() {
      if (Array.isArray(this.errorMessages) || typeof this.errorMessages === 'string') {
        return this.errorMessages
      }
      return getErrorMsg(this.errorMessages)
    },
  },
}
</script>

<style lang="scss">
.ui-text-field {
  &.disabled {
    opacity: 0.6;
  }
  .v-label--active {
    color: var(--v-black-darken3) !important;
  }
  fieldset,
  .v-text-field .v-input__control {
    border-color: var(--v-black-lighten6);
  }
  &.select-extended {
    fieldset {
      border-radius: 6px 0 0 6px;
    }
    .s-select__caret {
      background: none!important;
    }
  }

  .v-text-field__slot {
    .v-label {
      font-size: 14px !important;
      color: var(--v-black-darken1);
    }

    input {
      font-size: 12px;
      color: var(--v-black-darken3) !important;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--v-black-darken3);
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .v-input__append-inner {
    margin-top: 12px !important;
  }
}

.ui-text-field__append-outer-reset {
  .v-input__append-outer {
    margin-top: 0 !important;
  }
}
</style>
