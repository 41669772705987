export default [
    {
        path: '/settings/call-center-webs',
        name: 'pageCallCenterWebsList',
        meta: { title: 'callCenterWebs.listTitle' },
        component: () => import('@/pages/settings/call-center-webs'),
    },
    {
        path: '/settings/call-center-webs/create',
        name: 'pageCallCenterWebsCreate',
        meta: { title: 'callCenterWebs.createFormTitle' },
        component: () => import('@/pages/settings/call-center-webs/create'),
    },
    {
        path: '/settings/call-center-webs/:id/edit',
        name: 'pageCallCenterWebsEdit',
        meta: { title: 'callCenterWebs.editFormTitle' },
        component: () => import('@/pages/settings/call-center-webs/id/edit'),
    },
]
