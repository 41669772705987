import { cloneDeep } from 'lodash'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    active: 1,
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedDeliveryWarehouseFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)
  filters.active = filter.active
  return filters
}
