import UserFilterPresetRepository from '@/repository/admin/userFilterPresetRepository'

const actions = {
  async searchFilterPreset(context, filters) {
    const { data } = await UserFilterPresetRepository.list(filters)
    return data.items
  },
  createFilterPreset(context, payload) {
    return UserFilterPresetRepository.post(payload)
  },
  updateFilterPreset(context, payload) {
    const { id, ...data } = payload
    return UserFilterPresetRepository.put(id, data)
  },
  deleteFilterPreset(context, payload) {
    return UserFilterPresetRepository.delete(payload)
  },
}

export default {
  namespaced: true,
  actions,
}
