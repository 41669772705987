<template>
  <div class="d-flex align-center">
    <v-btn
      class="px-0"
      :disabled="value === minValue"
      min-width="16"
      height="16"
      x-small
      tile
      @click="decrement()"
      depressed
    >
      <v-icon color="primary base" size="8">mdi-minus</v-icon>
    </v-btn>
    <div class="body-1 mx-2 black--text text--darken-3">{{ value }}</div>
    <v-btn
      class="px-0"
      :disabled="value === maxValue"
      min-width="16"
      height="16"
      x-small
      tile
      @click="increment()"
      depressed
    >
      <v-icon color="primary base" size="10">mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'UICounter',
  props: {
    value: {
      type: Number,
      default: 1,
    },
    minValue: {
      type: Number,
      default: 1,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
  },
  methods: {
    increment() {
      this.$emit('input', this.value + 1)
    },
    decrement() {
      if (this.value === 1) {
        return false
      }
      this.$emit('input', this.value - 1)
    },
  },
}
</script>

<style scoped></style>
