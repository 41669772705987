export default [
  {
    path: '/settings/permission',
    name: 'pagePermission',
    meta: { title: 'page.pagePermission', roles: ['SETTING_ACCESS_CONTROL_VIEW'] },
    component: () => import('@/pages/settings/permission'),
  },
  {
    path: '/settings/permission/:permission/:title/edit/',
    name: 'pageViewPermission',
    meta: { title: 'page.pageViewPermission', roles: ['SETTING_ACCESS_CONTROL_VIEW'] },
    component: () => import('@/pages/settings/permission/permission/edit'),
  },
]