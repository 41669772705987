import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'
import moment from 'moment'
import { DATE_FORM_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
    firstGroup: 'city',
    secondGroup: 'offer',
    groupDateBy: 'day',
    filterDateBy: 'created_at',
    deliveryServices: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    isActiveCity: 1,
    countries: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    regions: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    districts: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    cities: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    warehouses: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    checkFrom: null,
    checkTo: null,
    offers: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    products: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    totalType: null,
    deliveryType: null,
  },
  search: '',
  clearCache: 1,
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedDeliveryServiceAnalyticFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)

  filters.deliveryServices.filter.id.value = joinBySymbol(filter.deliveryServices)
  filters.countries.filter.id.value = joinBySymbol(filter.countries)
  filters.regions.filter.id.value = joinBySymbol(filter.regions)
  filters.districts.filter.id.value = joinBySymbol(filter.districts)
  filters.cities.filter.id.value = joinBySymbol(filter.cities)
  filters.warehouses.filter.id.value = joinBySymbol(filter.warehouses)
  filters.offers.filter.id.value = joinBySymbol(filter.offers)
  filters.products.filter.id.value = joinBySymbol(filter.products)
  filters.checkFrom = filter.checkFrom
  filters.checkTo = filter.checkTo
  filters.isActiveCity = filter.isActiveCity
  filters.groupDateBy = filter.groupDateBy
  filters.totalType = filter.totalType
  filters.deliveryType = filter.deliveryTypes
  filters.dateFrom = joinBySymbol(filter.dateFrom)
  filters.dateTo = joinBySymbol(filter.dateTo)
  filters.firstGroup = joinBySymbol(filter.firstGroup)
  filters.secondGroup = joinBySymbol(filter.secondGroup)
  filters.filterDateBy = joinBySymbol(filter.filterDateBy)

  return filters
}
