import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'
import { DATE_FORM_FORMAT } from '@/constans/date'
import moment from 'moment'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
    country: null,
    offers: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    offerGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    offerNiches: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    webs: [],
    landings: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    salesModel: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    salesType: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    operators: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    userGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    regions: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
  },
  search: '',
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedDistributionCheck(filter) {
  const filters = cloneDeep(defaultFilters.filter)
  filters.offers.filter.id.value = joinBySymbol(filter.offers)
  filters.offerGroups.filter.id.value = joinBySymbol(filter.offerGroups)
  filters.offerNiches.filter.id.value = joinBySymbol(filter.offerNiches)
  filters.landings.filter.id.value = joinBySymbol(filter.landings)
  filters.salesModel.filter.id.value = joinBySymbol(filter.offerSalesModels)
  filters.salesType.filter.id.value = joinBySymbol(filter.salesTypes)
  filters.operators.filter.id.value = joinBySymbol(filter.operators)
  filters.userGroups.filter.id.value = joinBySymbol(filter.userGroups)
  filters.regions.filter.id.value = joinBySymbol(filter.regions)
  filters.webs = Array.isArray(filter.orderWebs)
    ? filter.orderWebs.map(item => item.id || item)
    : filter.orderWebs
  filters.dateFrom = joinBySymbol(filter.dateFrom)
  filters.dateTo = joinBySymbol(filter.dateTo)
  filters.country = joinBySymbol(filter.country)
  return filters
}
