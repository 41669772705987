import moment from 'moment/moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    status: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    orderSubstatus: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    city: {
      id: {
        value: '',
        operator: 'in',
      },
      district: {
        id: {
          value: '',
          operator: 'in',
        },
        region: {
          id: {
            value: '',
            operator: 'in',
          },
          country: {
            id: {
              value: '',
              operator: 'in',
            },
          },
        },
      },
    },
    operator: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    prepayment: {
      value: '',
      operator: 'in',
    },
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
      niche: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    deliveryService: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    warehouse: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    createdAt: {
      value: `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${
        moment().endOf('month').format(DATE_TIME_FORM_FULL_FORMAT,)}`,
      operator: 'bwn',
    },
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
    selectedColumns: null,
  },
  cfFilter: {
    0: {
      name: {
        operator: 'like',
        value: '',
      },
      lastName: {
        operator: 'like',
        value: '',
      },
      phone: {
        operator: 'like',
        value: '',
      },
      id: {
        operator: 'like',
        value: '',
      },
    },
  },
}
