export default [
  {
    path: '/settings/order-tag',
    name: 'pageOrderTagList',
    meta: { title: 'page.pageOrderTagList', roles: ['SETTING_ORDER_TAG_VIEW'] },
    component: () => import('@/pages/settings/order-tag/index.vue'),
  },
  {
    path: '/settings/order-tag/create',
    name: 'pageOrderTagCreate',
    meta: { title: 'page.pageOrderTagCreate', roles: ['SETTING_ORDER_TAG_VIEW'] },
    component: () => import('@/pages/settings/order-tag/create'),
  },
  {
    path: '/settings/order-tag/:id/edit',
    name: 'pageOrderTagEdit',
    meta: { title: 'page.pageOrderTagEdit', roles: ['SETTING_ORDER_TAG_VIEW'] },
    component: () => import('@/pages/settings/order-tag/id/edit'),
  },
]
