import moment from "moment/moment";
import {DATE_TIME_FORM_FULL_FORMAT} from "@/constans/date";

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    offers: null,
    country: null,
    service: null,
    template: null,
    createdAt: {
      value: `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${
        moment().endOf('month').format(DATE_TIME_FORM_FULL_FORMAT,)}`,
      operator: 'bwn',
    },
    active: 1,
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export const smsFilters = {
  pagination: {
    limit: 10,
    page: 1,
  },
  filter: {},
  sortBy: ['id'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export default function customSmsCampaignLabel(option) {
  return option.senderName
}
