import moment from 'moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    order: {
      offer: {
        id: {
          value: '',
          operator: 'in',
        },
        groups: {
          id: {
            value: '',
            operator: 'in',
          },
        },
        niche: {
          id: {
            value: '',
            operator: 'in',
          },
        },
      },
      operator: {
        id: {
          value: '',
          operator: 'in',
        },
        groups: {
          id: {
            value: '',
            operator: 'in',
          },
        },
      },
    },
    status: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    updatedAt: {
      value: `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${
        moment().endOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}`,
      operator: 'bwn',
    },
  },
  custom_filter: {
    call_time: null,
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
