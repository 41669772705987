import moment from 'moment/moment'
import { DATE_FORM_FORMAT } from '@/constans/date'

export const defaultFilters = {
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
        warehouseStock: null,
        order: null,
        date: {
            value: `${moment().format(DATE_FORM_FORMAT)} 00:00:00|${moment().format(DATE_FORM_FORMAT)} 23:59:59`,
            operator: 'bwn',
        },
    },
    sortBy: ['id'],
    sortDesc: [true],
    clientOptions: {
        filterPanelExpanded: false,
    },
}
