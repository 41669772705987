export default [
    {
        path: '/settings/write-off-history',
        name: 'pageWriteOffHistoryList',
        meta: { title: 'writeOffHistory.listTitle', roles: ['SETTING_WAREHOUSE_WRITE_OFF_HISTORY'] },
        component: () => import('@/pages/settings/write-off-history'),
    },
    // {
    //     path: '/settings/write-off-history/create',
    //     name: 'pageWriteOffHistoryCreate',
    //     meta: { title: 'writeOffHistory.createFormTitle' },
    //     component: () => import('@/pages/settings/write-off-history/create'),
    // },
    // {
    //     path: '/settings/write-off-history/:id/edit',
    //     name: 'pageWriteOffHistoryEdit',
    //     meta: { title: 'writeOffHistory.editFormTitle' },
    //     component: () => import('@/pages/settings/write-off-history/id/edit'),
    // },
]