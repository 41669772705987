import approveSpeed from '@/router/call-centre/approve-speed'
import callController from '@/router/call-centre/call-controller'
import campaign from './campaign'
import dashboardOperator from './dashboard-operator'
import klnStatistic from './kln-statistic'
import klnAssessment from './kln-assessment'
import klnOperatorAssessment from '@/router/call-centre/kln-operator-assessment'
import logAuth from '@/router/call-centre/log-auth'
import logOrder from '@/router/call-centre/log-order'
import order from './order'
import orderFraud from '@/router/call-centre/order-fraud'
import orderImport from './order-import'
import orderOperatorImport from './order-operator-import'
import offerNorms from './offer-norms'
import operatorGrade from './operator-grade'
import orderOperator from '@/router/call-centre/order-operator'
import orderTrash from './order-trash'
import orderTrashChecked from './order-trash-cheched'
import orderTrashDispute from './order-trash-dispute'
import tickets from './tickets'
import questionnairesOrder from './questionnaires-order'
import workTime from './work-time'
import exportReports from '@/router/call-centre/export-reports'
import callCentreStatistics from '@/router/call-centre/call-centre-statistics'
import suspiciousOrders from '@/router/call-centre/suspicious-orders'
import callStatistics from '@/router/call-centre/call-statistics'
import penalty from '@/router/call-centre/penalty'
import logWatchNumber from '@/router/call-centre/log-watch-number'
import logAccounts from '@/router/call-centre/log-accounts'
import logAbnormal from '@/router/call-centre/log-abnormal'
import operatorSchedule from '@/router/call-centre/operator-schedule'
import ccStatisticsDashboard from '@/router/call-centre/cc-statistics-dashboard'
import autocall from '@/router/call-centre/autocall'
import myTickets from "@/router/call-centre/my-tickets";
import distributionCheck from '@/router/call-centre/distribution-check'
import rejectedStatistics from '@/router/call-centre/rejected-statistics'
// __generator_imports_call-centre__
import operatorStatusesRoute from './operator-statuses'
import averageCheck from '@/router/call-centre/average-check' // do not remove! generator import

const routers = [
  {
    path: '/call-centre',
    redirect: '/call-centre/dashboard',
    component: () => import('@/layouts/LayoutAdmin.vue'),
    children: [
      {
        path: '/call-centre/dashboard',
        name: 'callCentreManagerDashboard',
        meta: {
          title: 'page.pageCallCentreManagerDashboard',
          roles: [
            'CC_DASHBOARD_ORDER',
            'CC_DASHBOARD_APPROVE_PERCENT',
            'CC_DASHBOARD_AVERAGE_TIME_IN_STATUS',
            'CC_DASHBOARD_APPROVE_BY_TIME',
            'CC_DASHBOARD_CANCEL_NON_RANSOM',
          ],
        },
        component: () => import('@/pages/manager/Dashboard'),
      },
      ...approveSpeed,
      ...callCentreStatistics,
      ...callController,
      ...callStatistics,
      ...campaign,
      ...ccStatisticsDashboard,
      ...dashboardOperator,
      ...exportReports,
      ...klnAssessment,
      ...klnStatistic,
      ...klnOperatorAssessment,
      ...logAbnormal,
      ...logAccounts,
      ...logAuth,
      ...logOrder,
      ...logWatchNumber,
      ...order,
      ...orderFraud,
      ...orderImport,
      ...orderOperatorImport,
      ...offerNorms,
      ...operatorGrade,
      ...operatorSchedule,
      ...orderOperator,
      ...orderTrash,
      ...orderTrashChecked,
      ...orderTrashDispute,
      ...penalty,
      ...tickets,
      ...questionnairesOrder,
      ...workTime,
      ...suspiciousOrders,
      ...autocall,
      ...myTickets,
      ...distributionCheck,
      ...rejectedStatistics,
      ...averageCheck,
      // __imported_entities_call-centre__
      ...operatorStatusesRoute, // do not remove! generator import
    ],
  },
]

export default routers
