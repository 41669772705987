import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import i18n from '@/plugins/vue-i18n'
import callCentreRouters from './call-centre'
import logisticsRouters from './logistics'
import managerRouters from './manager'
import settingsRouters from './settings'
import auth from './auth'
import noticeDepartmentRouters from './notice-department'
import {toQueryString} from "@/helpers/functions";
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    ...auth,
    {
      path: '/',
      component: () => import('@/layouts/LayoutDefault.vue'),
      children: [
        {
          path: '/',
          name: 'pageMain',
          meta: { title: 'page.pageMain' },
          component: () => import('@/pages/Main.vue'),
        },
        {
          path: '/manual-procedures-restart',
          name: 'TestPage',
          meta: { title: 'Testing API' },
          component: () => import('@/pages/settings/test/Test.vue'),
        },
        ...callCentreRouters,
        ...logisticsRouters,
        ...managerRouters,
        ...noticeDepartmentRouters,
        ...settingsRouters,
        {
          path: '/403',
          name: 'pageError403',
          meta: { title: 'page.pageError403', noRedirect: true },
          component: () => import('@/pages/error/Error-403.vue'),
        },
        {
          path: '/404',
          name: 'pageError404',
          meta: { title: 'page.pageError404', noRedirect: true },
          component: () => import('@/pages/error/Error-404.vue'),
        },
      ],
    },

    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true },
  ],
})

const whiteList = ['loginPage', 'restorePage', 'recoveryPassword', 'qrCodePage'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  store.dispatch('AuthModule/onVerifyAuth').then(() => {
    const isAuthenticated = store.getters['AuthModule/isAuthenticated']
    if (whiteList.includes(to.name)) {
      next()
      return
    }

    if (!isAuthenticated) {
      // next({ name: 'loginPage', query: to.meta?.noRedirect ? {} : { path: to.path, ...to.query } })
      if(window.location.href.includes('auth/login')) return;
      // Remove work_time_log то fix statues for different users
      window.localStorage.removeItem('filters_work_time')
      window.location.href = (() => {
        const {protocol, host} = window.location
        return protocol + '//' + host + '/auth/login?' + toQueryString(to.meta?.noRedirect ? {} : { path: to.path, ...to.query })
      })()
      return
    }

    if (store.getters['ProfileModule/profileHasFullAccess']) {
      next()
      return
    }

    const profilePermissions = store.getters['ProfileModule/profilePermissions']
    const hasAccessPage =
      !Array.isArray(to.meta.roles) || to.meta.roles.some(role => profilePermissions.has(role))

    if (!hasAccessPage) {
      next({ name: 'pageError403' })
      return
    }
    next()
  })
  if (to.name !== from.name) store.dispatch('ModalModule/closeAll')
  // set page tab name
  document.title = i18n.t(`${to.meta.title}`)
})

router.onError((error) => {
  const pattern = /Loading chunk \d+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    window.location.reload();
  }
});
export default router
